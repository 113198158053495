import { createContext, useContext, useState } from 'react'

interface GlobalNavContextProps {
  isPresent: boolean
  setIsPresent: (isPresent: boolean) => void
  showHeader: boolean
  setShowHeader: (isPresent: boolean) => void
}
export const GlobalNavContext = createContext<GlobalNavContextProps>({
  isPresent: true,
  setIsPresent: () => {
    return
  },
  showHeader: false,
  setShowHeader: () => {
    return
  },
})
export const useGlobalNavContext = () => {
  const context = useContext(GlobalNavContext)

  if (context === undefined) {
    throw new Error(
      'useStickyHeaderContext must be used within a StickyHeaderContextProvider'
    )
  }
  return context
}

export const GlobalNavContextProvider = ({ children }) => {
  const [isPresent, setIsPresent] = useState(true)
  const [showHeader, setShowHeader] = useState(false)
  return (
    <GlobalNavContext.Provider
      value={{
        isPresent,
        setIsPresent,
        showHeader,
        setShowHeader,
      }}
    >
      {children}
    </GlobalNavContext.Provider>
  )
}
