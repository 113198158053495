import { createContext, useContext, useState } from 'react'

interface ModalContextProps {
  showingFormModal: boolean
  setShowingFormModal: (showingFormModal: boolean) => void
  showingSubmitFormModal: boolean
  setShowingSubmitFormModal: (showingFormModal: boolean) => void
}
export const ModalContext = createContext<ModalContextProps>({
  showingFormModal: false,
  showingSubmitFormModal: false,
  setShowingFormModal: () => {
    return
  },
  setShowingSubmitFormModal: () => {
    return
  },
})
export const useModalContext = () => {
  const context = useContext(ModalContext)

  if (context === undefined) {
    throw new Error(
      'useModalContext must be used within a ModalContextProvider'
    )
  }
  return context
}

export const ModalContextProvider = ({ children }) => {
  const [showingFormModal, setShowingFormModal] = useState(false)
  const [showingSubmitFormModal, setShowingSubmitFormModal] = useState(false)
  return (
    <ModalContext.Provider
      value={{
        showingFormModal,
        setShowingFormModal,
        showingSubmitFormModal,
        setShowingSubmitFormModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
