export const HISTORY_LENGTH = 4
export const INITIAL_HISTORY = new Array(HISTORY_LENGTH).fill('')

export const initPageViewHistory = () => {
  if (typeof window === 'undefined') return

  const existingOrInitial = JSON.parse(
    localStorage.getItem('page-referrals') || JSON.stringify(INITIAL_HISTORY)
  )
  if (!Array.isArray(existingOrInitial) || existingOrInitial.length != 4) {
    localStorage.setItem('page-referrals', JSON.stringify(INITIAL_HISTORY))
  }
}
