type WindowWithFB = Window & typeof globalThis & { fbq: any }

export const pageview = () => {
  if (window && (window as WindowWithFB).fbq) {
    ;(window as WindowWithFB).fbq('track', 'PageView')
  }
}
// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
  if (window && (window as WindowWithFB).fbq) {
    ;(window as WindowWithFB).fbq('track', name, options)
  }
}
