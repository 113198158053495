import { MenuItem } from '@/components/common/Types'
import { createContext, useContext, useState } from 'react'

interface MobileMenuContextProps {
  showMobileMenu: boolean
  setShowMobileMenu: (showMobileMenu: boolean) => void
  updatedMenuLinks: MenuItem[]
  setUpdatedMenuLinks: (updatedMenuLinks: any) => void
}
export const MobileMenuContext = createContext<MobileMenuContextProps>({
  showMobileMenu: false,
  setShowMobileMenu: () => {
    return
  },
  updatedMenuLinks: [],
  setUpdatedMenuLinks: () => {
    return
  },
})
export const useMobileMenuContext = () => {
  const context = useContext(MobileMenuContext)

  if (context === undefined) {
    throw new Error(
      'useMobileMenuContext must be used within a MobileMenuContextProvider'
    )
  }
  return context
}

export const MobileMenuContextProvider = ({ children }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [updatedMenuLinks, setUpdatedMenuLinks] = useState([])

  return (
    <MobileMenuContext.Provider
      value={{
        showMobileMenu,
        setShowMobileMenu,
        updatedMenuLinks,
        setUpdatedMenuLinks,
      }}
    >
      {children}
    </MobileMenuContext.Provider>
  )
}
